<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_user()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <v-card-title>Dane użytkownika</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>

                  <v-text-field
                    v-model="user_data.name"
                    label="Nazwa"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.email"
                    label="Email"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.number"
                    label="Telefon"
                    color="primary"
                  ></v-text-field>

                  <!-- <v-select
                    v-model="user_data.role_id"
                    :items="role"
                    item-text="name"
                    item-value="id"
                    label="Rola"
                    single-line
                    :rules="$store.state.rules.not_null"
                  ></v-select> -->

                  <v-autocomplete
                    v-model="user_data.role_id"
                    :items="role"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Rola"
                    :rules="$store.state.rules.not_null"
                  ></v-autocomplete>
              
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć użytkownika?"
      @close="dialog = false;"
      @confirm="delete_user()"
    />
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    id: 0,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista użytkowników',
        disabled: false,
        to: '/uzytkownicy',
      },
      {
        text: 'Edytuj użytkownika',
        disabled: true,
        to: '/edit',
      }
    ],

    user_data: {
      id: 1,
      email: 'Rzeszów',
      name: 'Andrzej Maślak z ZOO',
      numebr: '1233',
    },

    role: [],
    
    search: ''
  }),
  methods: {
    edit_user() {
      if(this.$refs.form.validate()) {
        console.log(this.user_data.role_id)
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("name", this.user_data.name);
        formData.append("email", this.user_data.email);
        formData.append("role_id", this.user_data.role_id);
        if(this.user_data.number != null)formData.append("number", this.user_data.number);

        this.$axios({url: this.$store.state.api +'/customer/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/uzytkownicy');
            this.$store.commit("snackbar", {
              text: "Wprowadzono zmiany",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
    delete_user(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/customer/'+this.id, data: {}, method: 'DELETE' })
        .then(resp => {
          this.$router.push('/uzytkownicy');
          this.$store.commit("snackbar", {
            text: "Usunięto użytkownika",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    this.id = this.$route.params.id;
    console.log(this.id);

    // Edytowanie linku
    this.breadcrumb[2].to = '/uzytkownik/'+this.id;

    this.$store.commit('loader');
    let loader = 2;
    this.$axios({url: this.$store.state.api +'/customer/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.user_data = resp.data.user;
        this.role = resp.data.roles;
        console.log(resp.data);
        // this.user_data = resp.data.user_assign;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
</style>